import React from "react";
import Table from "../components/Table";
import '../pages/portal.css'
import '../components/customCharts.css'
import '../components/charts.min.css'
import TopNap from '../components/TopNav'

import { useParams } from 'react-router'
import LayoutFooter from "../components/LayoutFooter";

console.log('stage 1')
const Portal = () => {
    console.log('stage 2')
    const params = useParams()
    console.log('stage 3')
    const data = [
        {
            "_id": 1,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": "99999",
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H01",
            "Variable_name": "Total income",
            "Variable_category": "Financial performance",
            "Value": 757504,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 2,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H04",
            "Variable_name": "Sales, government funding, grants and subsidies",
            "Variable_category": "Financial performance",
            "Value": 674890,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 3,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H05",
            "Variable_name": "Interest, dividends and donations",
            "Variable_category": "Financial performance",
            "Value": 49593,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 4,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H07",
            "Variable_name": "Non-operating income",
            "Variable_category": "Financial performance",
            "Value": 33020,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 5,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H08",
            "Variable_name": "Total expenditure",
            "Variable_category": "Financial performance",
            "Value": 654404,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 6,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H09",
            "Variable_name": "Interest and donations",
            "Variable_category": "Financial performance",
            "Value": 26138,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 7,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H10",
            "Variable_name": "Indirect taxes",
            "Variable_category": "Financial performance",
            "Value": 6991,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 8,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H11",
            "Variable_name": "Depreciation",
            "Variable_category": "Financial performance",
            "Value": 27801,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 9,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H12",
            "Variable_name": "Salaries and wages paid",
            "Variable_category": "Financial performance",
            "Value": 123620,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 10,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H13",
            "Variable_name": "Redundancy and severance",
            "Variable_category": "Financial performance",
            "Value": 275,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 11,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H14",
            "Variable_name": "Salaries and wages to self employed commission agents",
            "Variable_category": "Financial performance",
            "Value": 2085,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 12,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H19",
            "Variable_name": "Purchases and other operating expenses",
            "Variable_category": "Financial performance",
            "Value": 452963,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 13,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H20",
            "Variable_name": "Non-operating expenses",
            "Variable_category": "Financial performance",
            "Value": 14806,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 14,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H21",
            "Variable_name": "Opening stocks",
            "Variable_category": "Financial performance",
            "Value": 68896,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 15,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H22",
            "Variable_name": "Closing stocks",
            "Variable_category": "Financial performance",
            "Value": 69127,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 16,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H23",
            "Variable_name": "Surplus before income tax",
            "Variable_category": "Financial performance",
            "Value": 103330,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 17,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H24",
            "Variable_name": "Total assets",
            "Variable_category": "Financial position",
            "Value": 2512677,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 18,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H25",
            "Variable_name": "Current assets",
            "Variable_category": "Financial position",
            "Value": 730587,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 19,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H26",
            "Variable_name": "Fixed tangible assets",
            "Variable_category": "Financial position",
            "Value": 591351,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 20,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H29",
            "Variable_name": "Other assets",
            "Variable_category": "Financial position",
            "Value": 1190739,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 21,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H30",
            "Variable_name": "Total equity and liabilities",
            "Variable_category": "Financial position",
            "Value": 2512677,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 22,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H31",
            "Variable_name": "Shareholders funds or owners equity",
            "Variable_category": "Financial position",
            "Value": 813949,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 23,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H32",
            "Variable_name": "Current liabilities",
            "Variable_category": "Financial position",
            "Value": 933093,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 24,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars (millions)",
            "Variable_code": "H33",
            "Variable_name": "Other liabilities",
            "Variable_category": "Financial position",
            "Value": 765635,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 25,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars",
            "Variable_code": "H34",
            "Variable_name": "Total income per employee count",
            "Variable_category": "Financial ratios",
            "Value": 400900,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 26,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Dollars",
            "Variable_code": "H35",
            "Variable_name": "Surplus per employee count",
            "Variable_category": "Financial ratios",
            "Value": 54700,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 27,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Percentage",
            "Variable_code": "H36",
            "Variable_name": "Current ratio",
            "Variable_category": "Financial ratios",
            "Value": 78,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        },
        {
            "_id": 28,
            "Year": 2021,
            "Industry_aggregation_NZSIOC": "Level 1",
            "Industry_code_NZSIOC": 99999,
            "Industry_name_NZSIOC": "All industries",
            "Units": "Percentage",
            "Variable_code": "H37",
            "Variable_name": "Quick ratio",
            "Variable_category": "Financial ratios",
            "Value": 71,
            "Industry_code_ANZSIC06": "ANZSIC06 divisions A-S (excluding classes K6330, L6711, O7552, O760, O771, O772, S9540, S9601, S9602, and S9603)"
        }]


    const getRandomArbitrary = () => {
        return Math.random() * (1 - 0) + 0;
    }

    let p = []
    for (let i = 2016; i < 2024; i++) {
        p.push(i);
    }
    const barData = p.map(i =>
        <tr key={i}>
            <th scope="row"> {i} </th>
            <td style={{ "--size": getRandomArbitrary() }}><span className="data"> {Math.round(10000 * getRandomArbitrary())} </span><span className="tooltip">{10000 * getRandomArbitrary()}</span></td>
        </tr>)

    //console.log(params)

    /*
        const cook = (dat) => {
            setCookie('test_cookie', dat,
                {
                    expires: Date()
                    , path: "/"
                })
        }
    */


    return (
        <>

            <div className="header">
                <h1>{params.msg}:{params.priority}</h1>
                <p>Resize the browser window to see the effect.</p>
            </div>

            <TopNap />

            <div className="row">
                <div className="leftcolumn">
                    <div className="card">
                        <h2>TITLE HEADING</h2>
                        <h5>Title description, Dec 7, 2017</h5>
                        <div className="fakeimg" style={{ height: '200px' }}>Image</div>
                        <p>Some text..</p>
                    </div>
                    <div className="card">
                        <h2>TITLE HEADING</h2>
                        <h5>Title description, Sep 2, 2017</h5>
                        <div className="fakeimg" style={{ height: '200px' }}>Image</div>
                        <p>Some text..</p>
                    </div>
                </div>
                <div className="rightcolumn">
                    <div className="card">
                        <h2>About Me</h2>
                        <div id="bar-chart-1">
                            <table className="charts-css column show-heading show-labels show-primary-axis">
                                <caption> 3D Effect Example #1 </caption>
                                <thead>
                                    <tr>
                                        <th scope="col"> Year </th>
                                        <th scope="col"> Progress </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {barData}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card">
                        <h3>Popular Post</h3>
                        <div>
                            <Table data={data} pageSize={20} />
                        </div>
                    </div>
                    <div className="card">
                        <h3>Follow Me</h3>
                        <p>Some text..</p>
                    </div>
                </div>
            </div >

            <LayoutFooter />
        </>
    )
}

export default Portal;