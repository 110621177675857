export const getDateTime = (n) => {
    let dt = new Date(n);
    return dt.toLocaleString("en-US");
}
export const getDateTimeObj = (n) => {
    let dt = new Date(n);
    //return dt.toLocaleString("en-US");
    return dt;
}
export const getDate = (n) => {
    let dt = new Date(n);
    return dt.toLocaleDateString("en-US");
}

export const validDate = (s) => {
    //let dateInput = s; // DD/MM/YYYY format
    //let dateArray = dateInput.split("/");
    //let newDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    let dateObj = new Date(s);

    if (!isNaN(dateObj)) {
        return true
    }
    else {
        return false;
    }
}

export const getCurrentTimeStamp = () => {
    const dt = new Date();
    return dt.getTime();
}

export const getCurrentTimeISO = () => {
    const dt = new Date();
    return dt.toISOString().substring(0, 19);
}

export const getTimeStampFromString = (s) => {
    return Date.parse(s)
}




export const filterDict = (arr, value) => {
    value = String(value).toLowerCase();
    return arr.filter(o =>
        Object.entries(o).some(entry =>
            String(entry[1]).toLowerCase().includes(value)
        )
    );
}