import React from "react";
import '../pages/portal.css'
import '../components/customCharts.css'
import '../components/charts.min.css'
import TopNap from '../components/TopNav'
import LayoutFooter from "../components/LayoutFooter";
import DropDownList from "../components/DropDownList";
import TreeList from "../components/TreeList";

function Home() {
    let dt = [
        {
            _id: 1
            , name: 'Category 1'
        },
        {
            _id: 2
            , name: 'Category 2'
        },
        {
            _id: 3
            , name: 'Category 33'
        }
    ]
    return (
        <>

            <div className="header">
                <h1>My Website</h1>
                <p>Resize the browser window to see the effect.</p>
            </div>
            <TopNap />

            <div className="row">
                <div className="leftcolumn">
                    <div className="card">
                        <h2>TITLE HEADING</h2>
                        <h5>Title description, Dec 7, 2017</h5>
                        <div className="fakeimg" style={{ height: '200px' }}>Image</div>
                        <p>Some text..</p>
                    </div>
                    <div className="card">
                        <h2>TITLE HEADING</h2>
                        <h5>Title description, Sep 2, 2017</h5>
                        <div className="fakeimg" style={{ height: '200px' }}>Image</div>
                        <p>Some text..</p>
                    </div>
                </div>
                <div className="rightcolumn">
                    <div className="card">
                        <h2>About Me</h2>
                        <p>about me</p>
                    </div>
                    <div className="card">
                        <h3>Popular Post</h3>
                        <p>pop posts</p>
                    </div>
                    <div className="card">
                        <h3>Follow Me</h3>
                        <p>Some text..</p>
                        <DropDownList data={dt} selected="1" />
                        <p>Next</p>
                        <TreeList/>
                    </div>
                </div>
            </div >

            <LayoutFooter />
        </>
    )
}

export default Home;