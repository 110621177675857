import React from "react";

import { useDispatch, useSelector } from 'react-redux'
import { updateItem, closeModal, addItem } from '../store/emailSlice'
import { getCurrentTimeISO, getTimeStampFromString, getCurrentTimeStamp, getDateTime } from '../commons/functions';

const ItemModal = ({ payload }) => {
    const list = useSelector((state) => state.email.list)
    const dispatch = useDispatch() //Access to resucer functions

    const formUpdateItem = () => {
        console.log(formFieldRrefs.lastUpdate.value);
        dispatch(updateItem({
            id: payload.id,
            subject: formFieldRrefs.subject.value,
            from: formFieldRrefs.from.value,
            to: formFieldRrefs.to.value,
            body: formFieldRrefs.body.value,
            read: formFieldRrefs.read.checked,
            lastUpdate: getTimeStampFromString(formFieldRrefs.lastUpdate.value)
        }))
    }
    const formAddItem = () => {
        dispatch(addItem({
            subject: formFieldRrefs.subject.value,
            from: formFieldRrefs.from.value,
            to: formFieldRrefs.to.value,
            body: formFieldRrefs.body.value,
            read: formFieldRrefs.read.checked
        }))
    }

    let formFieldRrefs = {
        from: null,
        to: null,
        subject: null,
        body: null,
        read: null,
        lastUpdate: null
    }
    let formFieldDefaults = {
        from: null,
        to: null,
        subject: null,
        body: null,
        read: null,
        lbl: null,
        lastUpdate: null
    }

    if (!payload.open)
        return null


    if (payload.id > -2) {
        formFieldDefaults.subject = list.filter((item) => item.id === payload.id)[0].subject
        formFieldDefaults.from = list.filter((item) => item.id === payload.id)[0].from
        formFieldDefaults.to = list.filter((item) => item.id === payload.id)[0].to
        formFieldDefaults.body = list.filter((item) => item.id === payload.id)[0].body
        formFieldDefaults.read = list.filter((item) => item.id === payload.id)[0].read
        formFieldDefaults.lbl = "Update"
        formFieldDefaults.lastUpdate = getDateTime(list.filter((item) => item.id === payload.id)[0].lastUpdate)
    }
    else {
        formFieldDefaults.name = ''
        formFieldDefaults.subject = ''
        formFieldDefaults.from = 'majdis@gmail.com'
        formFieldDefaults.to = ''
        formFieldDefaults.body = ''
        formFieldDefaults.read = false
        formFieldDefaults.lbl = "Add"
        formFieldDefaults.lastUpdate = getDateTime(getCurrentTimeStamp())
    }

    return (

        < >
            <dialog open className="containerModal" id="formStyle">
                <form>
                    <h1>Email Information</h1>
                    <section>
                        <p>
                            <label for="from">
                                <span>From: </span>
                                <strong><span aria-label="required">*</span></strong>
                            </label>
                            <input type="text" id="from" name="emailFrom" ref={(input) => { formFieldRrefs.from = input }} defaultValue={formFieldDefaults.from} required />
                        </p>
                        <p>
                            <label for="to">
                                <span>To: </span>
                                <strong><span aria-label="required">*</span></strong>
                            </label>
                            <input type="text" id="to" name="emailTo" ref={(input) => { formFieldRrefs.to = input }} defaultValue={formFieldDefaults.to} required />
                        </p>
                        <p>
                            <label for="from">
                                <span>Subject: </span>
                                <strong><span aria-label="required">*</span></strong>
                            </label>
                            <input type="text" id="ubject" pattern="[0-9]+" name="emailSubject" ref={(input) => { formFieldRrefs.subject = input }} defaultValue={formFieldDefaults.subject} required />
                        </p>
                        <p>
                            <label for="date">
                                <span>Date: </span>
                                <strong><span aria-label="required">*</span></strong>
                            </label>
                            <input step={1} type="datetime-local" id="date" defaultValue={getCurrentTimeISO(formFieldDefaults.lastUpdate)} ref={(input) => { formFieldRrefs.lastUpdate = input }} />
                        </p>
                        <p>
                            <label for="body">
                                <span>Body: </span>
                                <strong><span aria-label="required">*</span></strong>
                            </label>
                            <textarea id="body" ref={(input) => { formFieldRrefs.body = input }} defaultValue={formFieldDefaults.body} required />
                        </p>
                        <p>
                            <label for="read">
                                <span>Read: </span>
                                <strong><span aria-label="required">*</span></strong>
                            </label>
                            <input type="checkbox" id="read" ref={(input) => { formFieldRrefs.read = input }} defaultChecked={formFieldDefaults.read} />
                        </p>
                        <button className="modalBtn" onClick={() => {
                            if (payload.id > -2) {
                                formUpdateItem();
                            }
                            else {
                                formAddItem();
                            }
                        }}>{formFieldDefaults.lbl}</button>
                        &nbsp;<button className="modalBtn" onClick={() => dispatch(closeModal())}>Close</button>
                    </section>
                </form>
            </dialog>
        </ >
    )
}

export default ItemModal