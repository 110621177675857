import logo from './logo.svg';
import './App.css';
import List from './components/List';
import Portal from './pages/Portal';
import Home from './pages/Home';
import AuthenticatedRoute from './components/authenticatedRoute';
import { ToastContainer } from 'react-toastify';

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Demo from './pages/Demo';
function App() {
  return (<>
    <BrowserRouter>
      <Routes>
        <Route exact path='/' Component={Home} />
        <Route exact path='/list' Component={List} />
        <Route exact path='/demo' Component={Demo} />

        <Route exact path='/test' element={<div>this is a test page</div>} />
        <Route exact path='/amazon/:id' element={<div>this is a amazon page</div>} />
        <Route exact path='/portal' element={
          <AuthenticatedRoute>
            <Portal />
          </AuthenticatedRoute>
        } />

      </Routes>
    </BrowserRouter>
    <ToastContainer />
  </>
  );
}

export default App;
