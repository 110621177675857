import { createSlice } from '@reduxjs/toolkit'

export const protectSlice = createSlice({
    name: 'protect',
    initialState: {
        authenticated: true
        , authenticatedTime: 0
    },
    reducers: {
        setAuth: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes.
            // Also, no return statement is required from these functions.
            //if (!validDate(action.payload.date)) console.log(`Invalid date: ${action.payload.date}`)
            state.authenticated = action.payload.stat

        },
        getAuth: (state) => {
            return true
        }

    },
})

// Action creators are generated for each case reducer function
export const { setAuth } = protectSlice.actions

export default protectSlice.reducer