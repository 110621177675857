import React from "react";


function LayoutFooter() {
    return (
        <div className="footer">
            <h2>Footer</h2>
        </div>
    )
}

export default LayoutFooter;