import React from "react";


function TopNap() {
    return (
        <div className="navbar">
            <div className="dropdown">
                <button className="dropbtn">Home
                    <i className="fa fa-caret-down"></i>
                </button>
                <div className="dropdown-content">
                    <a href="/portal">Portal</a>
                    <a href="/demo">Demo</a>
                    <a href="/list">List</a>
                    <a href="/">Home</a>
                </div>
            </div>
            <a href="portal/descripton/123">News</a>
            <div className="dropdown">
                <button className="dropbtn">Dropdown
                    <i className="fa fa-caret-down"></i>
                </button>
                <div className="dropdown-content">
                    <a href="/portal">Portal</a>
                    <a href="/list">List</a>
                    <a href="/notesure">Note Sure</a>
                </div>
            </div>

        </div >)
}

export default TopNap;