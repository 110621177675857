import React, { useState, useEffect } from "react";
import './TableStyle.css'

const Table = (props) => {

    // number of records to show per page
    //const size = props.pageSize;
    const headerRow = props.headerRow ? props.headerRow : props.data[0];
    let pGData = null;

    const getMaxPage = (len, pSize) => {
        return Math.floor(len / pSize) + ((len % pSize) > 0 ? 1 : 0);
    }
    const paginate = (array, page_size, page_number) => {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const filterDict = (arr, filter) => {
        filter = String(filter).toLowerCase();
        return arr.filter(o =>
            Object.entries(o).some(field =>
                String(field[1]).toLowerCase().includes(filter)
            )
        );
    }

    const dictSum = (arr, key) => {
        let sum = 0;
        arr.forEach(function (arrayItem) {
            sum += arrayItem[key];
        });
        return sum
    }



    const [state, setState] = useState({
        rows: props.data,
        sort: 1,
        searchTxt: '',
        page: 1,
        txtPage: 1,
        count: props.data.length,
        maxPage: getMaxPage(props.data.length, props.pageSize),
        pageSize: props.pageSize
    })


    useEffect(() => {
        let g = findTxtLenAverages(props.data);

        g.forEach(function (arrayItem) {
            arrayItem['perc'] = Math.round(100 * arrayItem['average'] / dictSum(g, 'average'))
        });
        setState({ ...state, colWidth: g })

    }, [props.data])



    const findTxtLenAverages = (arr) => {
        var avg = Array.from(arr.reduce(
            (acc, obj) => Object.keys(obj).reduce(
                (acc, key) => acc.set(key, (acc.get(key) || []).concat(String(obj[key]).length))
                ,
                acc),
            new Map()),
            ([name, values]) =>
                ({ name, average: Math.round(values.reduce((a, b) => a + b) / values.length) })
        );
        avg.forEach(function (arrayItem) {
            arrayItem['perc'] = Math.round(100 * arrayItem['average'] / dictSum(avg, 'average'))
        })
        return avg;
    }

    const getWidthPercent = (key) => {
        if (state.colWidth)
            return state.colWidth.filter((o) => o.name = key)[0].perc
    }


    const listSort = (field) => {
        let list1 = [...state.rows];
        if (typeof list1[0][field] == 'number')
            list1.sort((b, a) => (state.sort * (a[field] - b[field])));
        else if (state.sort === 1)
            list1.sort((a, b) => (a[field] > b[field]) ? 1 : (b[field] > a[field] ? -1 : 0));
        else
            list1.sort((b, a) => (a[field] > b[field]) ? 1 : (b[field] > a[field] ? -1 : 0));

        setState({
            ...state,
            sort: -1 * (state.sort)
            , page: 1
            , txtPage: 1
            , rows: list1
        })

    }

    //const maxPage = Math.floor(table.rows.length / size) + ((table.rows.length % size) > 0 ? 1 : 0);


    const handlePage = (e) => {
        if (e.keyCode === 13)
            setState({ ...state, page: parseInt(e.target.value) })

    }
    const handlePageTxtChange = (e) => {
        //only numbers
        let v = e.target.value
        if (v === '')
            v = "1"

        let val = parseInt(v.replace(/[^0-9]/g, ''))

        if (val > state.maxPage)
            alert(`Page value cannot exceed ${state.maxPage}`);
        else
            setState({ ...state, txtPage: val });

        //setState({ page: e.target.value });
    }

    const setPG = (pg) => {
        setState({ ...state, page: pg, txtPage: pg })
    }

    const handlePageSizeChange = (e) => {
        setState({
            ...state,
            pageSize: e.target.value,
            page: 1,
            txtPage: 1,
            maxPage: getMaxPage(state.rows.length, e.target.value)

        });
    }
    const handleTxtSearchChange = (e) => {
        let v = e.target.value;
        if (v.length > 0) {
            const newData = filterDict(props.data, v)
            setState({
                ...state,
                rows: newData,
                maxPage: getMaxPage(newData.length, state.pageSize),
                searchTxt: v,
                count: newData.length,
                page: 1,
                txtPage: 1
            });
        }
        else
            setState({
                ...state,
                rows: props.data,
                maxPage: getMaxPage(props.data.length, state.pageSize),
                searchTxt: '',
                page: 1,
                txtPage: 1
            });
    }

    //console.log(`Page ${state.page} of ${state.maxPage} with a total of ${state.count} items and a page size of ${state.pageSize}`);

    pGData = paginate(state.rows, state.pageSize, state.page)

    return (
        <>
            <div>
                <section align="left" >
                    <button onClick={() => setPG(1)}
                        disabled={state.page === 1}>first</button>
                    <button onClick={() => setPG(state.page - 1)}
                        disabled={state.page === 1}>previous</button>
                    <input type="text" key="spage_text" onKeyDown={handlePage} size="10" value={state.txtPage} onChange={handlePageTxtChange} />
                    <button onClick={() => setPG(state.page + 1)}
                        disabled={state.page === state.maxPage}>next</button>
                    <button onClick={() => setPG(state.maxPage)}
                        disabled={state.page === state.maxPage}>last</button>
                    <input type="text" key="search_text" size="10" value={state.searchTxt} onChange={handleTxtSearchChange} />
                    <select value={state.pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                    </select>
                </section>
                <table id="dynamicTable">
                    <thead>
                        <tr >{Object.keys(headerRow).map((item, indexHeder) => (
                            <th width={getWidthPercent(item)} scope="col" key={indexHeder}><button className="headerButton" onClick={() => listSort(item)}>{item}</button></th>
                        ))}
                        </tr>
                    </thead>

                    <tbody>
                        {pGData.map((item, indexRow) => (
                            <tr key={indexRow}>
                                {Object.keys(item).map((fields, indexColumn) => (
                                    <td key={indexColumn}>{item[fields]}</td>
                                ))}
                            </tr>
                        ))}

                    </tbody>
                    <tfoot>
                        <tr key="-1">
                            <th colSpan={Object.keys(headerRow).length}>{`${state.count > 0 ? String(state.count) : 'No'} record${state.count > 1 || state.count === 0 ? 's' : ''} found...`}</th>
                        </tr>
                    </tfoot>
                </table>

            </div>
        </>

    );
}

export default Table;