import React, { useState } from "react";
import './DropDownList.css'


function DropDownList(props) {

    const [state, setState] = useState(
        {
            data: props.data
            , selected: (props.selected ? props.selected : '')
        }
    );

    //console.log(state.data)
    const CData = (state.data ? state.data : props.data);
    //const CData = props.data;

    //console.log(CData)

    const handleChange = (e) => {
        console.log(e.target.value)
        setState({ ...state, selected: e.target.value })
    }


    return (
        <div>
            <select className="select-box" value={state.selected} onChange={handleChange}>
                {CData.map((item, indexRow) => (
                    <option key={indexRow} value={item._id}>{item.name}</option>
                ))
                }
            </select>

        </div>)

}

export default DropDownList;