import React from 'react'
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"

const AuthenticatedRoute = ({ children }) => {
    const authenticated = useSelector((state) => state.protect.authenticated)

    let location = useLocation();

    if (!authenticated) {
        return <Navigate to="/" state={{ from: location }} replace />
    }
    return children

};

export default AuthenticatedRoute;