import React, { useEffect, useState } from "react";
import '../pages/portal.css'
import '../components/customCharts.css'
import '../components/charts.min.css'
import TopNap from '../components/TopNav'
import LayoutFooter from "../components/LayoutFooter";


//import logo from './logo.svg';
import { MdDelete, MdEdit } from 'react-icons/md';
import '../App.css';
import { useSelector, useDispatch } from 'react-redux'
import { deleteItem, openModal, toggleItemRead } from '../store/emailSlice'
import ItemModal from '../components/EmailModal'
import { getDateTime } from '../commons/functions';
import { useLocation } from 'react-router';
import Table from '../components/Table';
import '../components/charts.min.css'
import '../components/customCharts.css'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


function Demo() {

    const showToastMessage = (msg) => {
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
        });
    };


    const [state, setState] = useState({ data: [{ _id: 0, name: '' }], DataIsLoaded: false, })

    useEffect(() => {
        const LoadPromise = new Promise((resolve) =>
            fetch("https://datausa.io/api/data?drilldowns=Nation&measures=Population")
                .then((response) => response.json())
                .then((json) => setTimeout(() => { setState({ data: json.data, DataIsLoaded: true }); resolve(json) }, 2000))
        );

        toast.promise(LoadPromise, {
            pending: "Loading Data, please wait...",
            success: "Data Loaded Successfully! 👌",
            error: "error",

        }, { autoClose: 1500 },);
    }, []);


    let list1 = [...useSelector((state) => state.email.list)];
    const openID = useSelector((state) => state.email.openID)
    const openD = useSelector((state) => state.email.open)
    const location = useLocation();

    list1.sort((a, b) => { return b.lastUpdate - a.lastUpdate; });

    const dispatch = useDispatch()

    function loadTable() {

        if (!state.DataIsLoaded)
            return (<div>
                <h1> Loading Data, please wait... </h1>
            </div>)
        else
            return (<Table data={state.data} pageSize={20} />);

    }

    return (
        <>

            <div className="header">
               
                <h1>My Website</h1>
                <p>Resize the browser window to see the effect.</p>
            </div>
            <TopNap />

            <div className="row">
                <div className="leftcolumn">
                    <div className="card">
                        <h2>TITLE HEADING</h2>
                        <h5>Title description, Dec 7, 2017</h5>
                        <div className="fakeimg" style={{ height: '200px' }}>Image</div>
                        <p>Some text..</p>
                    </div>
                    <div className="card">
                        <h2>TITLE HEADING</h2>
                        <h5>Title description, Sep 2, 2017</h5>
                        <div className="fakeimg" style={{ height: '200px' }}>Image</div>
                        <p>Some text..</p>
                    </div>
                </div>
                <div className="rightcolumn">
                    <div className="card">
                        <h2>About Me</h2>
                        <div id="bar-chart-1">
                            <table class="charts-css column show-heading show-labels show-primary-axis">
                                <caption> 3D Effect Example #1 </caption>
                                <thead>
                                    <tr>
                                        <th scope="col"> Year </th>
                                        <th scope="col"> Progress </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row"> 2016 </th>
                                        <td Style="--size: 0.2;"><span class="data"> 20 </span><span class="tooltip">$156,225</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row"> 2017 </th>
                                        <td Style="--size: 0.4;"><span class="data"> 40 </span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row"> 2018 </th>
                                        <td Style="--size: 0.6;"><span class="data"> 60 </span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row"> 2019 </th>
                                        <td Style="--size: 0.8;"><span class="data"> 80% </span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row"> 2020 </th>
                                        <td Style="--size: 1;"><span class="data"> 100% </span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <header >
                            <button onClick={() => dispatch(openModal({ openID: -2 }))}>New Email {location.pathname}</button>
                            <ItemModal key={0} payload={{ open: openD, id: openID }}></ItemModal>
                            <div>
                                <div className="container">
                                    {
                                        list1.map((item) => {
                                            return (
                                                <div key={item.id} className="row">
                                                    <div className="column"> <input type="checkbox" checked={item.read} onChange={(e) => dispatch(toggleItemRead({ id: item.id }))} /></div>
                                                    <div className="column"><span style={{ textDecorationLine: item.status ? 'line-through' : 'none', textDecorationStyle: 'solid' }} className='itemDescription'>{item.subject} </span>
                                                        <div><span className="itemDate">{getDateTime(item.lastUpdate)}</span></div>
                                                    </div>
                                                    <div className="column"><span style={{ textDecorationLine: item.status ? 'line-through' : 'none', textDecorationStyle: 'solid' }} className='itemDescription'>{item.to} </span></div>
                                                    <div className="column">
                                                        <div className=".icon"
                                                            onClick={() => dispatch(deleteItem({ id: item.id }))}
                                                            //onKeyDown={() => handleDelete()}
                                                            tabIndex={0}
                                                            role="button">
                                                            <MdDelete /></div>
                                                    </div>
                                                    <div className="column">
                                                        <div
                                                            className=".icon"
                                                            onClick={() => dispatch(openModal({ openID: item.id }))}
                                                            //onKeyDown={() => handleDelete()}
                                                            tabIndex={1}
                                                            role="button">
                                                            <MdEdit /></div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div>
                                    {loadTable()}
                                </div>

                            </div>
                            <div></div>
                        </header >
                    </div>
                    <div className="card">
                        <h3>Popular Post</h3>
                        <p>pop posts</p>
                    </div>
                    <div className="card">
                        <h3>Follow Me</h3>
                        <p>Some text..</p>

                    </div>
                </div>
            </div >

            <LayoutFooter />
        </>
    )
}

export default Demo;