import { createSlice } from '@reduxjs/toolkit'
import { getCurrentTimeStamp } from '../commons/functions';
import { ToastContainer, toast } from 'react-toastify';

const showToastMessage = (msg, success) => {
    if (success)
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
        },);
    else
        toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
        },);
};


export const emailSlice = createSlice({
    name: 'email',
    initialState: {
        list: [],
        openID: -1,
        open: false,
    },
    reducers: {
        addItem: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes.
            // Also, no return statement is required from these functions.
            //if (!validDate(action.payload.date)) console.log(`Invalid date: ${action.payload.date}`)
            let l_id = state.list.length > 0 ? state.list.length + 1 : 1;
            state.list.push({
                id: l_id,
                subject: action.payload.subject,
                from: action.payload.from,
                to: action.payload.to,
                body: action.payload.body,
                read: action.payload.read,
                lastUpdate: getCurrentTimeStamp()
            })
            state.open = false;
            showToastMessage('Item successfully added!', true)

        },
        updateItem: (state, action) => {
            //console.log(action.payload.lastUpdate)
            const id = state.list.findIndex((item) => item.id === action.payload.id);
            state.list[id].subject = action.payload.subject;
            state.list[id].from = action.payload.from
            state.list[id].to = action.payload.to
            state.list[id].body = action.payload.body
            state.list[id].read = action.payload.read
            state.list[id].lastUpdate = action.payload.lastUpdate
            state.open = false;
            showToastMessage('Item successfully updated!', true)
        },
        deleteItem: (state, action) => {
            //state.list.splice(action.payload.index, 1);
            try {
                state.list.splice(state.list.findIndex((item) => item.id === action.payload.id), 1);
                showToastMessage('Item successfully deleted!', true)
            }
            catch (error) { showToastMessage('Error Occured', false) }

        },
        openModal: (state, action) => {
            state.openID = action.payload.openID;
            state.open = true;
            //console.log(action.openID)
        },
        closeModal: (state) => {
            state.open = false;
            //console.log(action.openID)
        },
        toggleItemRead: (state, action) => {
            state.list[action.payload.index].read = !state.list[action.payload.index].read
        }
    },
})

// Action creators are generated for each case reducer function
export const { addItem, updateItem, deleteItem, openModal, closeModal, toggleItemRead } = emailSlice.actions

export default emailSlice.reducer