import React from "react";
import './TreeList.css'
function TreeList() {

    return (
        <ul id="tree">
            <li>
                <details open>
                    <summary>Giant planets</summary>
                    <ul>
                        <li>
                            <details>
                                <summary>Gas giants</summary>
                                <ul>
                                    <li>Jupiter</li>
                                    <li>Saturn</li>
                                </ul>
                            </details>
                        </li>
                        <li>
                            <details>
                                <summary>Ice giants</summary>
                                <ul>
                                    <li>Uranus</li>
                                    <li>Neptune</li>
                                    <li>
                                        <details>
                                            <summary>Ice giants123</summary>
                                            <ul>
                                                <li>Uranus123</li>
                                                <li>Neptune123</li>
                                            </ul>
                                        </details>
                                    </li>
                                </ul>
                            </details>
                        </li>
                    </ul>
                </details>
            </li>
        </ul>
    )
}

export default TreeList;